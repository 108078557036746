"use strict";

//Fb incode js
window.fbAsyncInit = function () {
  FB.init({
    appId: '4535717663153845',
    cookie: true,
    xfbml: true,
    version: 'v12.0'
  });
  FB.AppEvents.logPageView();
};

(function (d, s, id) {
  var js,
      fjs = d.getElementsByTagName(s)[0];

  if (d.getElementById(id)) {
    return;
  }

  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'facebook-jssdk'); //utilities for cookies


function checkCookie() {
  var freshStart_cookie = getCookie("freshStartAism");

  if (freshStart_cookie == "") {
    $('#tutorial-modal').modal('show');
    setCookie("freshStartAism", false, 365);
  }
}

function setCookie(cname, cvalue) {
  var exdays = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  } else {
    document.cookie = cname + "=" + cvalue + ";";
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
} //End


var readmoreLabel = $('#readmoreLabel'),
    collapseTxt = $('#collapsepTxt'),
    dona_mobile__wrapper = $('.dona-mobile__wrapper'),
    lastScrollTop = 0,
    carousel = $('#carouseldonazioni'),
    carousel_indicator = $('#carouselindicator'),
    label_totale = $('#conteggio-attivita'),
    total_bound = -1,
    indicator = $('.carousel-indicators__elm[data-slide-to]'),
    socialLogin = $('#socialLogin'),
    socialSingin = $('#socialSingin'),
    socialSinginLink = $('#socialSinginLink'),
    url_redirect = "registrazione.aspx?",
    panelSocialMatch = $('#panelSocialMatch'),
    panelSocialMatched = $('#panelSocialMatched'),
    cambiaSocial = $("#cambiaSocial"); //var socialToken = $('#socialToken')
//var socialEmail = $('#socialEmail')
//var hiddenSocialLogin = $('#hiddenSocialLogin');

indicator.on('click', function (e) {
  $('.carousel-indicators__elm.active').removeClass('active');
  $('.carousel-indicators__elm[data-slide-to=' + $(this).attr('data-slide-to') + ']').addClass('active');
});
carousel_indicator.on('slide.bs.carousel', function (e) {
  var index = e.to * 4;
  carousel.carousel(index);
  $('.carousel-indicators__elm.active').removeClass('active');
  $('.carousel-indicators__elm[data-slide-to=' + index + ']').addClass('active');
});
carousel.on('slide.bs.carousel', function (e) {
  if (typeof label_totale !== 'undefined') {
    total_bound = label_totale.attr('data-totale');
    var index = e.to;
    index = (index + 1) * 4;

    if (index <= total_bound) {
      index = index < 10 ? '0' + index : index;
      label_totale.text(index);
    } else {
      label_totale.text(total_bound);
    }
  }
});
$(".collapse__faq").collapse();
collapseTxt.on('show.bs.collapse', function () {
  readmoreLabel.text('Leggi meno');
});
collapseTxt.on('hide.bs.collapse', function () {
  readmoreLabel.text('Leggi di più');
});
$(window).scroll(function (event) {
  var st = $(this).scrollTop();

  if (st > lastScrollTop) {
    dona_mobile__wrapper.slideUp('slow');
  } else {
    dona_mobile__wrapper.slideDown('slow');
  }

  lastScrollTop = st;
});
$(".scrollTo").on("click", function (e) {
  if (this.hash !== "") {
    e.preventDefault();
    var hash = this.hash; //offset = (hash == '#top') ? 0 : 4;

    var offset = -124;
    $("html, body").animate({
      scrollTop: $(hash).offset().top + offset
    }, 700, function () {});
  }
}); //Facebbok social Login

socialLogin === null || socialLogin === void 0 ? void 0 : socialLogin.on('click', function (_ev) {
  _ev.preventDefault();

  FB.getLoginStatus(function (response) {
    if (response.status == 'connected') {
      getCurrentUserInfoLogin(response);
    } else {
      FB.login(function (response) {
        if (response.authResponse) {
          getCurrentUserInfoLogin(response);
        } else {
          console.log('Error');
        }
      }, {
        scope: 'public_profile,email',
        return_scopes: true
      });
    }
  });
}); //socialSingin?.on('click', function (_ev) {
//    _ev.preventDefault()
//    FB.getLoginStatus(function (response) {
//        if (response.status == 'connected') {
//            getCurrentUserInfoLogin(response)
//        } else {
//            FB.login(function (response) {
//                if (response.authResponse) {
//                    getCurrentUserInfoLogin(response)
//                } else {
//                    console.error('Error');
//                }
//            }, { scope: 'email' });
//        }
//    });
//})
//socialSinginLink?.on('click', function (_ev) {
//    _ev.preventDefault()
//    FB.getLoginStatus(function (response) {
//        if (response.status == 'connected') {
//            getCurrentUserInfoSingIn(response)
//        } else {
//            FB.login(function (response) {
//                if (response.authResponse) {
//                    getCurrentUserInfoSingIn(response)
//                } else {
//                    console.log('Error');
//                }
//            }, { scope: 'email' });
//        }
//    });
//})

function getCurrentUserInfoLogin(res) {
  FB.api('/me?fields=name,email,first_name,last_name,id', function (user) {
    if (user.id && user.email) {
      var _res$authResponse, _res$authResponse2;

      user.token = (_res$authResponse = res.authResponse) === null || _res$authResponse === void 0 ? void 0 : _res$authResponse.accessToken;
      user.expiresIn = (_res$authResponse2 = res.authResponse) === null || _res$authResponse2 === void 0 ? void 0 : _res$authResponse2.expiresIn;
      user.social_type = 'fb';

      __doPostBack('socialLog', btoa(JSON.stringify(user)));
    } else {
      __doPostBack('socialLog', btoa(JSON.stringify("")));
    }
  });
} //function getCurrentUserInfoSingIn(res) {
//    FB.api('/me?fields=name,email,first_name,last_name,id', function ({ first_name, last_name, id, email }) {
//        if (id && email) {
//            url_redirect += `M=${email}`;
//            if (first_name) {
//                url_redirect += `&N=${first_name}`;
//            }
//            if (last_name) {
//                url_redirect += `&C=${last_name}`;
//            }
//            url_redirect += `&TF=${res.authResponse.accessToken}`;
//        }
//        location.replace(url_redirect);
//    });
//}
//Google social login


var googleUser = {};

var startApp = function startApp() {
  gapi.load('auth2', function () {
    //Find button, if not exist exit
    var buttonGoogleSocial = document.getElementById('socialLoginGo'),
        buttonGoogleSocialLink = document.getElementById('socialSinginLinkGo'),
        elementToClick = buttonGoogleSocial || buttonGoogleSocialLink;
    if (!elementToClick) return false; // Retrieve the singleton for the GoogleAuth library and set up the client.

    var auth2 = gapi.auth2.init({
      client_id: '361763660099-8rfgh7orsr2e10umpn7krb44hrfikfve.apps.googleusercontent.com',
      // Request scopes in addition to 'profile' and 'email'
      scope: 'profile email'
    }).then(function () {
      var auth2Is = gapi.auth2.getAuthInstance();
      auth2Is.attachClickHandler(elementToClick, {}, function (googleUser) {
        //var profile = googleUser.getBasicProfile();
        //var token_go = googleUser.getAuthResponse().id_token;
        //var user = {
        //    social_type: 'go',
        //    name: profile.getName(),
        //    first_name: profile.getName(),
        //    email: profile.getEmail(),
        //    token: token_go
        //};
        if (auth2Is.isSignedIn.get()) {
          GoogleLoginAuthResponse(auth2Is.currentUser.get());
        } else {
          auth2Is.signIn().then(function (googleUser) {
            GoogleLoginAuthResponse(googleUser);
          });
        }
        /*                    return false;*/

      }, function (error) {
        console.error(error);
      });
    }); //console.log('avanzo');
  });
};

function GoogleLoginAuthResponse(googleUser) {
  var profile = googleUser.getBasicProfile();
  var token_go = googleUser.getAuthResponse().id_token;
  var user = {
    social_type: 'go',
    name: profile.getName(),
    first_name: profile.getName(),
    email: profile.getEmail(),
    token: token_go
  };

  __doPostBack('socialLog', btoa(JSON.stringify(user)));
}

startApp();
cambiaSocial.on("click", function (e) {
  e.preventDefault();
  panelSocialMatched.hide();
  panelSocialMatch.show();
});